<template>
	<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
		<b-card no-body>
			<b-card-header>
				<h4>Ventas Por Zona Clientes</h4>
				<b-button @click="changeView" size="sm" variant="outline-primary" class="_btn-icon">
					<feather-icon size="18" :icon="isShowChart ? 'EyeIcon' : 'EyeOffIcon'" />
					<span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
				</b-button>
			</b-card-header>

			<!-- <div style="min-height: 200px!important; max-height: 200px!important;"> -->
			
			<vue-apex-charts v-if="isShowChart && !loading && sales.length > 0" ref="donutChart" type="pie"
				height="380"
				:options="chartOptions" :series="series" />
			

			<div ref="tableContainer" class="table-responsive b-table-sticky-header">
				<table v-if="!isShowChart"
					class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
					<thead>
						<tr>
							<th>Zona</th>
							<th>Importe</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in sales" :key="index">
							<td style="font-size: 10px;">{{ item.zona }}</td>
							<td style="font-size: 10px;">{{ formatCurrency(item.venta_Importe) }}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td class="font-weight-bold text-right">TOTAL</td>
							<td class="font-weight-bold">{{ formatCurrency(sales.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<!-- </div> -->
		</b-card>
	</b-overlay>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
	BOverlay, BButton,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()


export default {
	components: {
		BCard,
		BCardHeader,
		BCardTitle,
		BCardBody,
		BMediaBody,
		BMedia,
		BMediaAside,
		BAvatar,
		FeatherIcon,
		BOverlay,
		BButton,
		VueApexCharts,
	},
	props: {
		filterBy: {
			type: Object,
			required: true,
		},
		refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
	},
	data() {
		return {
			loading: false,
			sales: [],
			series: [],
			chartOptions: {
				chart: {
					type: 'donut',
				},
				legend: {
					show: true,
					position: 'bottom',
					fontFamily: 'Montserrat',
				},
				plotOptions: {
					pie: {
						startAngle: -90,
						endAngle: 90,
						offsetY: 10
					}
				},
				grid: {
					// padding: {
					// 	bottom: 10
					// }
				},
				labels: [],
				// responsive: [{
				// 	breakpoint: 480,
				// 	options: {
				// 		chart: {
				// 			width: 200
				// 		},
				// 		legend: {
				// 			position: 'bottom'
				// 		}
				// 	}
				// }]
			},

			isShowChart: true,
		}
	},
	computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        }
    },
	beforeDestroy() {
		window.removeEventListener('resize', this.updateChartData);
	},
	async mounted() {
		if(this.isLoadDashboard) {
			await this.getSalesCustomerZones()
			window.addEventListener('resize', this.updateChartData);
		}
	},
	methods: {
		kFormatter,
		changeView() {
			this.isShowChart = !this.isShowChart
			if (this.isShowChart) {
				// Asegurar que el gráfico se redimensiona al volver a ser visible
				this.$nextTick(() => {
					this.updateChartData()
				});
			}
		},
		updateChartData() {
			this.$nextTick(() => {

				this.chartOptions.labels = []
				this.series = []

				this.chartOptions.labels = this.sales.map(item => item.zona).flat();
				this.series = this.sales.map(item => item.venta_Importe).flat();

				//Forzar la actualización del gráfico
				// if (this.sales.length > 0 && this.series.length > 0) {
					// this.$refs.donutChart.updateSeries(this.series, true);
					this.$refs.donutChart.updateOptions(this.chartOptions, true, true, true);
				// }
			})
		},
		async getSalesCustomerZones() {
			this.sales = []
			const query = {
				...this.filterByDates(this.filterBy.key),
				vendedorId: this.filterBy.vendedor_Id,
			}

			if (this.filterBy && this.filterBy.key) {
				this.loading = true
				const { data } = await DashboardsResource.getSalesCustomerZones(query)
				this.loading = false
				
				if (data.isSuccesful && data.data != null && data.data.length > 0) {
					this.sales = data.data //_.orderBy(data.data, ['zona', 'asc'])
					this.updateChartData();
				}
			}
		}
	},
	watch: {
		refresh(value) {
			if(value) {
				this.getSalesCustomerZones()
			}	
		},
		filterBy(value) {
			this.getSalesCustomerZones()
		}
	}
}
</script>