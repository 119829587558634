<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>                    
                <h4 v-text="isShowChart ? 'Top 10 Ventas Por Cliente' : 'Detalle Ventas Por Cliente'" />
                <b-button @click="changeView" size="sm" variant="outline-primary" class="_btn-icon">
                    <feather-icon size="18" :icon="isShowChart ? 'EyeIcon' : 'EyeOffIcon'" />
                    <span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
                </b-button>                    
            </b-card-header>
            <vue-apex-charts v-if="isShowChart && !loading && sales.length > 0" ref="donutChart" type="donut" height="380" :options="donutChart.chartOptions" :series="donutChart.series" />

            <div ref="tableContainer" class="mb-0_ mt-1 table-responsive b-table-sticky-header">
                <table v-if="!isShowChart" class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                    <thead>
                    <tr>
                        <!-- <th></th> -->
                        <th>Cliente</th>
                        <th>Importe</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in sales" :key="index">
                            <!-- <td>{{ index + 1 }}</td> -->
                            <td style="font-size: 10px;">{{ item.cliente }}</td>
                            <td style="font-size: 10px;">{{ formatCurrency(item.venta_Importe) }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <!-- <td></td> -->
                            <td class="font-weight-bold text-right">TOTAL</td>
                            <td class="font-weight-bold">{{ formatCurrency(sales.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>

import moment from 'moment'
import {
    BCard, BImg, BAvatar, BCardText, BCardHeader, BOverlay, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { kFormatter } from '@core/utils/filter'

import DashboardsProvider from '@/providers/Dashboards'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
const DashboardsResource = new DashboardsProvider()

export default {
    components: {
        BCard,
        BCardHeader,
        BAvatar,
        BImg,
        BCardText,
        BOverlay,
        BButton,
        VueApexCharts,

        VuePerfectScrollbar,
        BCardActions,
        FeatherIcon,
    },
    props: {
        filterBy: {
            type: Object,            
            required: true,
        },
        refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            sales: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
            donutChart: {
                series: [],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontSize: '6px',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        $themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger
                    ],
                    dataLabels: {
                        enabled: true,
                        fontSize: '6px',
                        fontFamily: 'Montserrat',
                        formatter(val) {
                            if (val) {
                                return `${parseInt(val)}%`
                            }
                        },
                    },
                    tooltip: {
                        enabled: true, // Habilita el tooltip                        
                        y: {
                            formatter(val) {
                                if (val) {
                                    return kFormatter(val);
                                }
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: false,
                                    name: {
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                    },
                                    value: {
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                        formatter(val, opts) {
                                            if (val) {
                                                return `${kFormatter(val)}`
                                            }
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                        label: 'Global',
                                        formatter(opts) {
                                            const global = opts.globals.seriesTotals.reduce((carry, i) => {
                                                return carry + i
                                            }, 0)
                                            if (global) {
                                                return kFormatter(global)
                                            }
                                        },
                                    },
                                },
                            },
                        },
                    },
                    labels: [],
                },
            },
            isShowChart: true,
        }
    },
    computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        }
    },
    created() {
        
    },  
    beforeDestroy() {
        // window.removeEventListener('resize', this.updateChartData);
    },
    async mounted() {
        if (this.isLoadDashboard) {
            await this.getSalesCustomers()
        }
        // window.addEventListener('resize', this.updateChartData);
    },
    methods: {
        kFormatter,
        changeView() {
            this.isShowChart = !this.isShowChart

            // Forzar la actualización cuando se muestra el gráfico
            if (this.isShowChart) {
                this.$nextTick(() => {
                    this.updateChartData();  // Asegúrate de actualizar el gráfico cuando se vuelva a mostrar
                });
            }
        },
        updateChartData() {
            if (this.sales.length > 0) {
                this.$nextTick(() => {

                    this.donutChart.chartOptions.labels = [];
                    this.donutChart.series = [];

                    const labels = this.sales
                        .slice(0, 10)
                        .map(item => item.cliente)
                        .filter(label => typeof label === 'string' && label.trim() !== ''); // Filtrar solo strings válidos

                    const series = this.sales
                        .slice(0, 10)
                        .map(item => item.venta_Importe)
                        .filter(value => !isNaN(value) && value > 0); // Filtrar números válidos

                    // Verifica si los datos filtrados son válidos
                    if (labels.length > 0 && series.length > 0 && this.$refs.donutChart) {
                        this.donutChart.chartOptions.labels = labels;
                        this.donutChart.series = series;

                        // Forzar la actualización del gráfico
                        this.$refs.donutChart.updateSeries(this.donutChart.series, true);
                        this.$refs.donutChart.updateOptions(this.donutChart.chartOptions, true, true, true);
                    } else {
                        console.error("Datos inválidos para el gráfico: ", labels, series);
                    }
                });
            }
        },

        async getSalesCustomers() {
            this.sales = []

            const query = {
                ...this.filterByDates(this.filterBy.key),
                vendedorId: this.filterBy.vendedor_Id
            }

            if (this.filterBy && this.filterBy.key) {
                this.loading = true
                const { data } = await DashboardsResource.getSalesCustomers(query)
                this.loading = false            
                if (data.isSuccesful && data.data != null && data.data.length > 0) {
                    this.sales = data.data;
                    this.updateChartData();
                } else {
                    console.error("Datos inválidos de la API:", data);
                }
            }
        }
    },
    watch: {
        refresh(value) {
            if(value) {
                this.getSalesCustomers()
            }
        },
        filterBy(value) {
            this.getSalesCustomers()
        }
    }
}
</script>
