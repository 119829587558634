<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col xl="6" md="6">
				<sales-all-sellers :filterBy="filterBy" :refresh="false"/>
			</b-col>
			<b-col xl="6" md="6">
				
			</b-col>
		</b-row>

		
	</section>
</template>

<script>

import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import store from '@/store';
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import SalesAllSellers from '@/@core/components/sellers/SalesAllSellers.vue';

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
	name: 'DashboardAdministrator',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,

		//
		SalesAllSellers,
	},
	setup() {

		const loading = ref(false)

		const filterBy = computed(() => {
			const filter = store.getters['auth/getFilterOnDashboardSeller']
			const seller_id = store.getters['auth/getSellerId']
			return {
				...filter,
				vendedor_Id: seller_id
			}
		})

		return {
			filterBy,
		}
		
		onMounted(async () => {
		
		})

		return {
			loading,		

			filterBy,
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>