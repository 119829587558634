<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col xl="4" md="6">
				<sales-medal :filterBy="filterBy" :refresh="refresh"/>
			</b-col>
			<b-col xl="8" md="6">
				<sales-statistics :filterBy="filterBy" :refresh="refresh"/>
			</b-col>
		</b-row>

		<b-row class="match-height">
			<b-col lg="6" md="6">				
				<sales-annual-revenue :filterBy="filterBy" :refresh="refresh"/>
			</b-col>
			<b-col lg="6" md="6">
				<sales-analytic-revenue :filterBy="filterBy" :refresh="refresh" />
			</b-col>
		</b-row>

		<!-- Pie chart -->
		<b-row class="match-height">
			<b-col lg="4" md="6">
				<sales-customers :filterBy="filterBy" :refresh="refresh"/>
			</b-col>

			<b-col lg="4" md="6">
				<sales-articles-lines :filterBy="filterBy" :refresh="refresh"/>
			</b-col>

			<b-col lg="4" md="6">
				<sales-articles-groups :filterBy="filterBy" :refresh="refresh"/>
			</b-col>
		</b-row>
		<b-row class="match-height">
			<b-col lg="4" md="6">
				<sales-type-payments :filterBy="filterBy" :refresh="refresh"/>
			</b-col>
			<b-col lg="4" md="6">
				<sales-customer-zones :filterBy="filterBy" :refresh="refresh"/>
			</b-col>
			<b-col lg="4" md="6">
				<sales-customer-types :filterBy="filterBy" :refresh="refresh"/>
			</b-col>
		</b-row>
    </section>
</template>

<script>

import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
	BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import store from '@/store';
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import SalesMedal from '@/@core/components/sellers/SalesMedal.vue';
import AnalyticsCongratulation from '@/@core/components/sellers/AnalyticsCongratulation.vue';
import SalesCustomers from '@/@core/components/sellers/SalesCustomers.vue';
import SalesArticlesLines from '@/@core/components/sellers/SalesArticlesLines.vue';
import SalesArticlesGroups from '@/@core/components/sellers/SalesArticlesGroups.vue';
import SalesTypePayments from '@/@core/components/sellers/SalesTypePayments.vue';
import SalesCustomerZones from '@/@core/components/sellers/SalesCustomerZones.vue';
import SalesCustomerTypes from '@/@core/components/sellers/SalesCustomerTypes.vue';
import SalesStatistics from '@/@core/components/sellers/SalesStatistics.vue';
import SalesAnalyticRevenue from '@/@core/components/sellers/SalesAnalyticRevenue.vue';
import SalesAnnualRevenue from '@/@core/components/sellers/SalesAnnualRevenue.vue';


export default {
	name: 'DashboardAdministrator',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BButton,
		BFormGroup,
		vSelect,

		//
		FeatherIcon,
		SalesMedal,
		AnalyticsCongratulation,
		SalesCustomers,
		SalesArticlesLines,
		SalesArticlesGroups,
		SalesTypePayments,
		SalesCustomerZones,
		SalesCustomerTypes,
		SalesStatistics,
		SalesAnalyticRevenue,
		SalesAnnualRevenue,
		
	},
	props: {
		refresh: {
			type: Boolean,
			default: () => false,
			required: true,
		}
	},
	computed: {
		isAdministrator() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] : false
		},
	},
	setup() {

		const filterBy = computed(() => {
			const filter = store.getters['auth/getFilterOnDashboardSeller']
			const seller_id = store.getters['auth/getSellerId']
			return {
				...filter,
				vendedor_Id: seller_id
			}
		})

		return {
			filterBy,
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>