<template>
	<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
		<b-card no-body>
			<b-card-header>
				<h4>Comparación de Ventas por Día</h4>
				<b-button @click="changeView" size="sm" variant="outline-primary" class="_btn-icon">
					<feather-icon size="18" :icon="isShowChart ? 'EyeIcon' : 'EyeOffIcon'" />
					<span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
				</b-button>
			</b-card-header>

			<b-card-body class="pb-0">
				<!-- apex chart -->
				<vue-apex-charts v-if="isShowChart && !loading && sales.length > 0" ref="chartLine" type="line"
					height="400" :options="chartOptions" :series="series" />

				<div ref="tableContainer" class="table-responsive b-table-sticky-header">
					<table v-if="!isShowChart"
						class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
						<thead>							
							<tr>
								<th>Día</th>
								<th v-for="month in months" :key="month">{{ month }}</th>
							</tr>
						</thead>
						<tbody>							
							<tr v-for="day in allDays" :key="day">
								<td>{{ day }}</td>
								<td v-for="month in months" :key="month">
									{{ formatCurrency(getImporte(day, month)) }}
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td class="font-weight-bold text-right">TOTAL</td>
								<td class="font-weight-bold">
									{{ formatCurrency(sales.filter(i => i.mes_Dummy === firstMonth).reduce((carry, item) => carry + item.venta_Importe, 0)) }}
								</td>
								<td class="font-weight-bold">
									{{ formatCurrency(sales.filter(i => i.mes_Dummy === secondMonth).reduce((carry, item) => carry + item.venta_Importe, 0)) }}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</b-card-body>
		</b-card>
	</b-overlay>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BButton, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import { kFormatter } from '@core/utils/filter'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()


export default {
	components: {
		VueApexCharts,
		BCardText,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardBody,
		BButton,
		BOverlay,
	},
	props: {
		filterBy: {
			type: Object,
			required: true,
		},
		refresh: {
			type: Boolean,
			default: () => false,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			sales: [],
			series: [],
			chartOptions: {
				chart: {
					toolbar: { show: false },
					zoom: { enabled: false },
					type: 'line',
					zoom: {
						enabled: false
					},
					// Hacer el gráfico fluido y responsivo
					width: '100%',
					height: 'auto',
					dropShadow: {
						enabled: true,
						top: 2,
						left: 2,
						blur: 4,
						opacity: 0.2,
					},
					padding: {
						top: 20,
						right: 20,
						bottom: 20,
						left: 20,
					},
					animations: {
						enabled: true,
						easing: 'easeinout',
						speed: 800, // La duración de la animación
					}            
				},
				markers: {
					size: 5,
					colors: [$themeColors.primary, $themeColors.warning],
					strokeColors: '#fff',
					strokeWidth: 2,
					hover: {
						size: 7
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth', // Líneas más suaves
					width: 2, // Ajustar el grosor de las líneas
				},
				colors: [$themeColors.primary, $themeColors.warning],
				// Hacer responsivo el gráfico según el tamaño de la pantalla
				responsive: [
					{
						breakpoint: 1000, // Ajustes para pantallas menores a 1000px
						options: {
							chart: {
								width: '100%',
							},
							legend: {
								position: 'bottom', // Cambiar la posición de la leyenda para pantallas pequeñas
							},
						},
					},
					{
						breakpoint: 600, // Ajustes para pantallas menores a 600px
						options: {
							chart: {
								width: '100%',
							},
							xaxis: {
								labels: {
									show: false, // Ocultar etiquetas para evitar sobrecarga visual
								},
							},
							yaxis: {
								labels: {
									show: false, // Ocultar etiquetas en pantallas pequeñas
								},
							},
						},
					},
				],
				legend: {
					show: true,
					position: 'top',
					fontFamily: 'Montserrat',
					// fontSize: '12px',
					labels: {
						colors: '#b9b9c3'
					},
					markers: {
						width: 19,
						height: 19,
						strokeWidth: 0,
						strokeColor: '#fff',
						fillColors: undefined,
						radius: 12,
						offsetX: 0,
						offsetY: 0
					},
					itemMargin: {
						horizontal: 10,
						vertical: 0
					},
					
				},
				xaxis: {
					title: {
						text: 'Ventas (Días)'
					},
					labels: {
						rotate: -45, // Rotar etiquetas para evitar superposición
						style: {
							fontSize: '12px', // Ajustar el tamaño de la fuente
						},
					},
					axisTicks: {
						show: true,
					},
					categories: [],
					axisBorder: {
						show: true,
					},
					tickPlacement: 'on',
				},
				yaxis: {
					tickAmount: 0,
					title: {
						text: 'Ventas (Importes)'
					},
					labels: {
						formatter(val) {
							if (val) {
								return kFormatter(val)
							}
						},
					},
				},
				tooltip: {
					// Personaliza el contenido del tooltip
					enabled: true, // habilita el tooltip
					shared: false, // si hay múltiples series, puedes usar esto para compartir el tooltip
					followCursor: true, // seguir el cursor del ratón
					intersect: false,
					style: {
						fontSize: '12px',  // Tamaño de fuente
						background: '#fff', // Color de fondo del tooltip
					},
					x: {
						show: true, // Ocultar el eje x
						formatter: (value) => {
							// Personaliza la salida según el índice de la serie
							return `Día ${value}`; // Aquí puedes personalizar el formato
						},
					},
					y: {
						formatter: (value, { seriesIndex, series }) => {
							// Personaliza la salida según el índice de la serie
							return `${kFormatter(value)}`; // Aquí puedes personalizar el formato
						},
					},
				},
			},
			isShowChart: true,
			currentMonth: '',
			lastMonth: '',
			firstMonth: 0,
			secondMonth: 0,
		}
	},
	computed: {
		// Obtener todos los días únicos
		allDays() {
			return [...new Set(this.sales.map(item => item.dia.trim()))];
		},
		// Obtener todos los meses únicos
		months() {
			return [...new Set(
				this.sales
				.map(item => item.mes.trim())
				.filter(mes => !mes.endsWith('.') && /^[A-Z]/.test(mes)) // Filtrar según las condiciones
			)];
		},
		isLoadDashboard() {
			return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
		}
	},
	async mounted() {
		if (this.isLoadDashboard) {
			await this.getSalesByMonthsAndDays()
		}
	},
	methods: {
		kFormatter,
		getImporte(day, month) {
			console.log([day, month])
			const entry = this.sales.find(item => item.dia.trim() === day && item.mes.trim() === month);
			return entry ? entry.venta_Importe : 0; // Si no hay datos, devolver 0
		},
		changeView() {
			this.isShowChart = !this.isShowChart

			// if (this.isShowChart) {
			// 	// Asegurar que el gráfico se redimensiona al volver a ser visible
			// 	this.$nextTick(() => {
			// 		this.updateChartData()
			// 	});
			// }
		},
		updateChartData() {
			this.$nextTick(() => {

				// this.chartOptions.labels = []
				// this.series = []

				// this.chartOptions.labels = this.sales.map(item => item.zona).flat();
				// this.series = this.sales.map(item => item.venta_Importe).flat();

				//Forzar la actualización del gráfico
				// if (this.sales.length > 0 && this.series.length > 0) {
				// this.$refs.donutChart.updateSeries(this.series, true);
				this.$refs.chartLine.updateOptions(this.chartOptions, true, true, true);
				// }
			})
		},
		async getSalesByMonthsAndDays() {
			this.sales = []
			this.series = []
			this.currentMonth = ''
			this.lastMonth = ''
			this.firstMonth = 0
			this.secondMonth = 0
			this.chartOptions.xaxis.categories = []

			const query = {
				...this.filterByDates(this.filterBy.key),
				vendedorId: this.filterBy.vendedor_Id
			}

			//Ajustamos el mes de inicio:
			const fechaFin = new Date(query.fechaFin);

			// Obtener el primer día del mes anterior
			const primerDiaMesAnterior = new Date(fechaFin.getFullYear(), fechaFin.getMonth() - 1, 1);

			// Formatear las fechas a 'YYYY-MM-DD'
			const fechaIni = primerDiaMesAnterior.toISOString().slice(0, 10);
			const fechaFinFormateada = fechaFin.toISOString().slice(0, 10);

			query.fechaIni = fechaIni
			query.fechaFin = fechaFinFormateada

			this.loading = true
			const { data } = await DashboardsResource.getSalesByMonthsAndDays(query)
			this.loading = false
			if (data.isSuccesful && data.data != null && data.data.length > 0) {

				this.sales = data.data //_.orderBy(data.data, ['mes_Dummy', 'dia_Dummy'], ['asc', 'asc'])

				this.firstMonth = parseInt(moment(fechaIni).format('M')) // Mes anterior
				this.secondMonth = parseInt(moment(fechaFin).format('M')) // Mes actual				

				//Obtener los dias del mes actual o seleccionado
				const daysInMonth = moment(fechaFin, "YYYY-MM").daysInMonth();
				const daysInLastMonth = moment(fechaIni, "YYYY-MM").daysInMonth();

				// for (let index = 1; index <= daysInMonth; index++) {
				// 	const finded = this.sales.find(i => i.dia == index && i.mes_Dummy === this.firstMonth)
				// 	if (!finded) {						
				// 		this.sales.push({
				// 			dia_Dummy: index,
				// 			dia: index.toString(),
				// 			mes_Dummy: this.firstMonth,
				// 			mes: moment().month(this.firstMonth - 1).format("MMM"),
				// 			venta_Importe: 0,
				// 			porcentaje: 0
				// 		})
				// 	}
				// }

				// for (let index = 1; index <= daysInLastMonth; index++) {
				// 	const finded = this.sales.find(i => i.dia == index && i.mes_Dummy === this.secondMonth)
				// 	if (!finded) {						
				// 		this.sales.push({
				// 			dia_Dummy: index,
				// 			dia: index.toString(),
				// 			mes_Dummy: this.secondMonth,
				// 			mes: moment().month(this.secondMonth - 1).format("MMM"),
				// 			venta_Importe: 0,
				// 			porcentaje: 0
				// 		})
				// 	}
				// }

				this.sales = _.orderBy(this.sales, ['mes_Dummy', 'dia_Dummy'], ['asc', 'asc'])
				
				// Filtrar datos por cada mes y ordenar por día
				const ventasMesAnterior = _.orderBy(this.sales.filter(i => i.mes_Dummy === this.firstMonth), ['dia_Dummy'], 'asc')
				const ventasMesActual = _.orderBy(this.sales.filter(i => i.mes_Dummy === this.secondMonth), ['dia_Dummy'], 'asc')

				// // Configurar categorías (días de la semana)
				const allDays = [...new Set([...ventasMesAnterior, ...ventasMesActual].map(i => i.dia))]
								
				// Asignar nombres y datos a las series
				this.currentMonth = moment(fechaFin).format('MMMM[,] YYYY')
				this.lastMonth = moment(fechaIni).format('MMMM[,] YYYY')

				const totalMesAnterior = ventasMesAnterior.reduce((carry, item) => carry + item.venta_Importe, 0)
				const totalMesActual = ventasMesActual.reduce((carry, item) => carry + item.venta_Importe, 0)

				this.series = [
					{
						name: `Ventas ${this.lastMonth} ${this.formatCurrency(totalMesAnterior)} `,
						data: ventasMesAnterior.map(i => i.venta_Importe)
					},
					{
						name: `Ventas ${this.currentMonth} ${this.formatCurrency(totalMesActual)}`,
						data: ventasMesActual.map(i => i.venta_Importe)
					}
				]
				this.chartOptions.xaxis.categories = allDays //.map(i => `Día ${i}`).flat()
			}
		},
	},
	watch: {
		refresh(value) {
			if (value) {
				this.getSalesByMonthsAndDays()
			}
		},
		filterBy(value) {
			this.getSalesByMonthsAndDays()
		}
	}

}
</script>
