<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <h4>Ventas Por Grupos</h4>
                <b-button @click="changeView" size="sm" variant="outline-primary" class="_btn-icon">
                    <feather-icon size="18" :icon="isShowChart ? 'EyeIcon' : 'EyeOffIcon'" />
                    <span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
                </b-button>  
            </b-card-header>
            
            <vue-apex-charts v-if="isShowChart && !loading && groups.length > 0" ref="donutChart" type="donut" heigth="380" :options="donutChart.chartOptions" :series="donutChart.series" />
            
            <div ref="tableContainer" class="mb-0_ mt-1 table-responsive b-table-sticky-header">
                <table v-if="!isShowChart" class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                    <thead>
                    <tr>
                        <th>Grupo</th>
                        <th>Importe</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in groups" :key="index">
                            <td style="font-size: 10px;">{{ item.grupo }}</td>
                            <td style="font-size: 10px;">{{ formatCurrency(item.venta_Importe) }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="font-weight-bold text-right">TOTAL</td>
                            <td class="font-weight-bold">{{ formatCurrency(groups.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>

        </b-card>
    </b-overlay>
</template>

<script>

import moment from 'moment'
import {
    BCard, BImg, BAvatar, BCardText, BCardHeader, BOverlay, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { kFormatter } from '@core/utils/filter'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    components: {
        BCard,
        BCardHeader,
        BAvatar,
        BImg,
        BCardText,
        BOverlay,
        BButton,
        VueApexCharts,

        VuePerfectScrollbar,
        BCardActions,
        FeatherIcon,
    },
    props: {
        filterBy: {
            type: Object,
            required: true,
        },
        refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            groups: [],
            donutChart: {
                series: [],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        $themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger
                    ],
                    dataLabels: {
                        enabled: true,
                        fontFamily: 'Montserrat',
                        formatter(val) {
                            return `${parseInt(val)}%`
                        },
                    },
                    tooltip: {
                        enabled: true, // Habilita el tooltip                        
                        y: {
                            formatter(val) {
                                return kFormatter(val);
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                    },
                                    value: {
                                        fontFamily: 'Montserrat',
                                        formatter(val, opts) {
                                            return `${kFormatter(val)}`
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontFamily: 'Montserrat',
                                        label: 'Global',
                                        formatter(opts) {
                                            const global = opts.globals.seriesTotals.reduce((carry, i) => {
                                                return carry + i
                                            }, 0)
                                            return kFormatter(global)
                                        },
                                    },
                                },
                            },
                        },
                    },
                    labels: [],
                },
            },
            isShowChart: true,
        }
    },
    computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        }
    },
    created() {

    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.updateChartData);
    },
    async mounted() {
        if (this.isLoadDashboard) {
            await this.getSalesArticlesGroups()
        }
        // window.addEventListener('resize', this.updateChartData);
    },
    methods: {
        kFormatter,
        changeView() {
            this.isShowChart = !this.isShowChart

            // Forzar la actualización cuando se muestra el gráfico
            if (this.isShowChart) {
                this.$nextTick(() => {
                    this.updateChartData();  // Asegúrate de actualizar el gráfico cuando se vuelva a mostrar
                });
            }
        },
        updateChartData() {
            this.$nextTick(() => {

                this.donutChart.chartOptions.labels = []
                this.donutChart.series = []

                if (this.groups.length > 0) {

                    this.donutChart.chartOptions.labels = this.groups.map(item => item.grupo).flat();
                    this.donutChart.series = this.groups.map(item => item.venta_Importe).flat();

                    // Forzar la actualización del gráfico
                    this.$refs.donutChart.updateSeries(this.donutChart.series);
                    this.$refs.donutChart.updateOptions(this.donutChart.chartOptions, true, true, true);
                }
            })
        },
        async getSalesArticlesGroups() {
            this.groups = []
            const query = {
                ...this.filterByDates(this.filterBy.key),
                vendedorId: this.filterBy.vendedor_Id
            }

            if (this.filterBy && this.filterBy.key) {
                this.loading = true
                const { data } = await DashboardsResource.getSalesArticlesGroups(query)
                this.loading = false
                if (data.isSuccesful && data.data != null && data.data.length > 0) {
                    this.groups = data.data
                    this.updateChartData()
                }
            }
        }
    },
    watch: {
        refresh(value) {
            if (value) {
                this.getSalesArticlesGroups()
            }
        },
        filterBy(value) {
            this.getSalesArticlesGroups()
        }
    }
}
</script>
