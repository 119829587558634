<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>                    
                <h4 v-text="isShowChart ? 'Top 10 Ventas Por Linea' : 'Detalle Ventas Por Lineas'" />
                <b-button @click="changeView" size="sm" variant="outline-primary" class="_btn-icon">
                    <feather-icon size="18" :icon="isShowChart ? 'EyeIcon' : 'EyeOffIcon'" />
                    <span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
                </b-button>                    
            </b-card-header>
            <vue-apex-charts v-if="isShowChart && !loading && articles.length > 0" ref="donutChart" type="pie" height="380" :options="donutChart.chartOptions" :series="donutChart.series" />

            <div ref="tableContainer" class="mb-0_ mt-1 table-responsive b-table-sticky-header">
                <table v-if="!isShowChart" class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                    <thead>
                    <tr>
                        <th>Linea</th>
                        <th>Importe</th>
                    </tr>
                    </thead>
                    <tbody v-for="(item, index) in items" :key="index">
                        <tr>
                            <td style="font-size: 10px;">{{ item.linea }}</td>
                            <td style="font-size: 10px;">{{ formatCurrency(item.venta_Importe) }}</td>
                        </tr>                        
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="font-weight-bold text-right">TOTAL</td>
                            <td class="font-weight-bold">{{ formatCurrency(items.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>

import moment from 'moment'
import {
    BCard, BImg, BAvatar, BCardText, BCardHeader, BOverlay, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { kFormatter } from '@core/utils/filter'

import DashboardsProvider from '@/providers/Dashboards'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
const DashboardsResource = new DashboardsProvider()

export default {
    components: {
        BCard,
        BCardHeader,
        BAvatar,
        BImg,
        BCardText,
        BOverlay,
        BButton,
        VueApexCharts,

        VuePerfectScrollbar,
        BCardActions,
        FeatherIcon,
    },
    props: {
        filterBy: {
            type: Object,
            required: true,
        },
        refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            articles: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
            donutChart: {
                series: [],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        // fontSize: '2rem',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        $themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger
                    ],
                    dataLabels: {
                        enabled: true,
                        // fontSize: '6px',
                        // fontFamily: 'Montserrat',
                        formatter(val) {
                            if (val) {
                                return `${parseInt(val)}%`
                            }
                        },
                    },
                    tooltip: {
                        enabled: true, // Habilita el tooltip                        
                        y: {
                            formatter(val) {
                                if (val) {
                                    return kFormatter(val);
                                }
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: false,
                                    name: {
                                        // fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                    },
                                },
                            },
                        },
                    },
                    labels: [],
                },
            },
            isShowChart: true,
        }
    },
    computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        },
        items() {
            // Agrupar por 'linea' y sumar 'venta_Importe' y 'porcentaje', además de incluir los artículos
            const groupedSales = this.articles.reduce((acc, item) => {
            const found = acc.find(i => i.linea === item.linea);
            
            if (found) {
                // Si ya existe la linea, sumamos el venta_Importe y el porcentaje
                found.venta_Importe += item.venta_Importe;
                found.porcentaje += item.porcentaje;
                // Añadimos el artículo al array de artículos de la línea
                found.articulos.push({
                articulo_Id: item.articulo_Id,
                articulo: item.articulo,
                clave_Articulo: item.clave_Articulo,
                venta_Importe: item.venta_Importe,
                porcentaje: item.porcentaje
                });
            } else {
                // Si no existe, la agregamos al array con el artículo correspondiente
                acc.push({
                    linea: item.linea,
                    venta_Importe: item.venta_Importe,
                    porcentaje: item.porcentaje,
                    articulos: [{
                        articulo_Id: item.articulo_Id,
                        articulo: item.articulo,
                        clave_Articulo: item.clave_Articulo,
                        venta_Importe: item.venta_Importe,
                        porcentaje: item.porcentaje
                    }]
                });
            }

            return acc;
            }, []);

            // Ordenar el resultado por 'venta_Importe' de mayor a menor
            const sortedGroupedSales = groupedSales.sort((a, b) => b.venta_Importe - a.venta_Importe);

            return sortedGroupedSales

        }
    },
    created() {
        
    },  
    beforeDestroy() {
        // window.removeEventListener('resize', this.updateChartData);
    },
    async mounted() {
        if(this.isLoadDashboard) {
            await this.getSalesArticlesLines()
        }
        // window.addEventListener('resize', this.updateChartData);
    },
    methods: {
        kFormatter,
        changeView() {
            this.isShowChart = !this.isShowChart
        },
        updateChartData(articles) {
            this.$nextTick(() => {                
                this.donutChart.chartOptions.labels = []
                this.donutChart.series = []

                if (articles.length > 0) {

                    // Agrupar por 'linea' y sumar 'venta_Importe' y 'porcentaje'
                    const groupedSales = articles.reduce((acc, item) => {
                        const found = acc.find(i => i.linea === item.linea);
                        
                        if (found) {
                            // Si ya existe la linea, sumamos el venta_Importe y el porcentaje
                            found.venta_Importe += item.venta_Importe;
                            found.porcentaje += item.porcentaje;
                        } else {
                            // Si no existe, la agregamos al array
                            acc.push({
                                linea: item.linea,
                                venta_Importe: item.venta_Importe,
                                porcentaje: item.porcentaje,
                            });
                        }

                        return acc;
                    }, []);

                    
                    // Ordenar el resultado por 'venta_Importe' de mayor a menor
                    const sortedGroupedSales = groupedSales.sort((a, b) => b.venta_Importe - a.venta_Importe);

                    // Forzar la actualización del gráfico
                    if (sortedGroupedSales.length > 0) {

                        this.donutChart.chartOptions.labels = sortedGroupedSales.slice(0, 10).map(item => item.linea).flat();
                        this.donutChart.series = sortedGroupedSales.slice(0, 10).map(item => item.venta_Importe).flat();

                        this.$refs.donutChart.updateSeries(this.donutChart.series, true);
                        this.$refs.donutChart.updateOptions(this.donutChart.chartOptions, true, true, true);
                    }
                }
                
            })
        },
        async getSalesArticlesLines() {
            this.articles = []
            const query = {
                ...this.filterByDates(this.filterBy.key),
                vendedorId: this.filterBy.vendedor_Id
            }

            if (this.filterBy && this.filterBy.key) {
                this.loading = true
                const { data } = await DashboardsResource.getSalesArticlesLines(query)
                this.loading = false
                if (data.isSuccesful && data.data != null && data.data.length > 0) {
                    this.articles = data.data
                    this.updateChartData(this.articles)
                }
            }
            
        }
    },
    watch: {
        refresh(value) {
            if (value) {
                this.getSalesArticlesLines()
            }  
        },
        filterBy(value) {
            this.getSalesArticlesLines()
        }
    }
}
</script>
